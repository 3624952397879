import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import useInterceptors from "./hooks/useInterceptors";
import useConfigLoader from "./hooks/useConfigLoader";
import { FeatureFlagProvider } from "./hooks/featureFlagsProvider";

import Header from "./components/Header";
import Loading from "./components/Loading";
import Messages from "./components/Messages";

import Dashboard from "./pages/Dashboard";
import Attachment from "./pages/Attachment";
import Admin from "./pages/Admin";
import NotFound from "./pages/NotFound";

import "./styles/styles.scss";

const App = React.memo(({ envConfig }: { envConfig: any }) => {
  const isReady = useInterceptors({ envConfig });

  useEffect(() => {
    document.title = envConfig?.appConfig?.title ?? "Invoice Review";
    document.documentElement.className = "";
    if (envConfig?.appConfig?.theme) {
      document.documentElement.classList.add(`theme-${envConfig.appConfig.theme}`);
    }
  }, [envConfig]);

  if (!isReady) {
    return <Loading />;
  }

  return (
    <FeatureFlagProvider>
      <Header />
      <Messages />
      <Switch>
        <Route exact path="/">
          <Dashboard envConfig={envConfig} />
        </Route>
        <Route path="/invoice/:id">
          <Attachment envConfig={envConfig} />
        </Route>
        <Route path="/admin">
          <Admin envConfig={envConfig} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </FeatureFlagProvider>
  );
});

const UnauthenticatedView = () => {
  const { instance: msalInstance } = useMsal();

  useEffect(() => {
    msalInstance.loginRedirect();
  }, [msalInstance]);

  return <Loading />;
};

const AppWithSecurity = () => {
  const { isConfigLoaded, envConfig, authConfig } = useConfigLoader();

  if (!isConfigLoaded) {
    return <Loading />;
  }

  return (
    <MsalProvider instance={authConfig}>
      <AuthenticatedTemplate>
        <App envConfig={envConfig} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <UnauthenticatedView />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

const AppWithRouter = () => (
  <Router>
    <AppWithSecurity />
  </Router>
);

export default AppWithRouter;
