import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchCustomers = createAsyncThunk("customers/fetch", async () => {
  return axios.get(`/customers?type=unassigned`).then((res) => res.data);
});

export const fetchAssignedCustomers = createAsyncThunk("customers/assigned", async () => {
  return axios.get(`/customers?type=assigned`).then((res) => res.data);
});

export const fetchCustomerRules = createAsyncThunk("customers/rules", async () => {
  return axios.get(`customers/list-rules`).then((res) => res.data);
});

export const addCustomerRule = (userId: string, userName: string, rule: string): Promise<{ success: boolean; }> => {
  const body = {
    userId,
    userName,
    rule
  };

  return axios.post(`customers/add-rule`, body);
};

export const removeCustomerRule = (userId: string, rule: string): Promise<{ success: boolean; }> => {
  const body = {
    userId,
    rule,
  };

  return axios.post(`customers/remove-rule`, body);
};

export const assignCustomersToUser = async (userId: string, customers: React.Key[]): Promise<{ success: boolean; }> => {
  const body = {
    userId: userId,
    customers: customers
  };
  return axios.post(`customers/assign`, body).then((res) => res.data);
};

export const unassignCustomers = async (customers: React.Key[]): Promise<{ success: boolean; }> => {
  const body = customers;
  return axios.post(`customers/unassign`, body).then((res) => res.data);
};

const customersSlice = createSlice({
  name: "customers",
  initialState: {
    loading: false,
    customerRules: [],
    assignedCustomers: [],
    unassignedCustomers: []
  },
  reducers: {},
  extraReducers: {
    [fetchCustomers.fulfilled.type]: (state, action) => {
      state.unassignedCustomers = action.payload;
      state.loading = false;
    },
    [fetchCustomers.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchCustomers.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [fetchAssignedCustomers.fulfilled.type]: (state, action) => {
      state.assignedCustomers = action.payload;
      state.loading = false;
    },
    [fetchAssignedCustomers.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchAssignedCustomers.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [fetchCustomerRules.fulfilled.type]: (state, action) => {
      state.customerRules = action.payload;
      state.loading = false;
    },
    [fetchCustomerRules.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchCustomerRules.rejected.type]: (state, action) => {
      state.loading = false;
    },
  }
});

export default customersSlice.reducer;

export interface Customer {
  id: string;
  name: string;
  assignedTo: string;
}

export interface CustomerRules {
  userId: string;
  userName: string;
  rules: Array<string>;
}

export interface AssignedCustomer {
  assignedTo: string;
  username: string;
  customers: Customer[];
}
