import React from "react";
import { useState } from "react";

import { Checkbox, Modal, Row } from "antd";

import { assignEmailToUser, assignInvoiceToUser, searchUsers } from "../store/userSlice";
import DebounceSelect from "./DebounceSelect";

interface AssignToModalProps {
  invoices: React.Key[];
  emails: React.Key[];
  isModalOpen: boolean;
  isCustomer: boolean;
  closePopup: (refresh: boolean, userId: string) => void;
}

function AssignToModal(props: AssignToModalProps) {
  const [assignToUser, setAssignedToUser] = useState<any>();
  const [unassignedGlobalUser, setUnassignedGlobalUser] = useState<boolean>(false);

  const fetchUserList = async (criteria: string): Promise<any[]> => {
    return searchUsers(criteria);
  };

  const onCancel = (refresh: boolean = false, userId: string = "") => {
    setAssignedToUser(null);
    props.closePopup(refresh, userId);
  };

  const handleAssignTo = () => {
    if (props.emails.length) {
      assignEmailToUser(props.emails, assignToUser.key, assignToUser.label).then((res) => {
        onCancel(true);
      });
    } else if (props.invoices.length) {
      assignInvoiceToUser(props.invoices, assignToUser.key, assignToUser.label).then((res) => {
        onCancel(true);
      });
    } else if (props.isCustomer) {
      const assignedToUserId = unassignedGlobalUser ? "unassigned" : assignToUser.key;
      onCancel(true, assignedToUserId);
    }
  };

  const handleUnassignedGlobalUserToggle = (e) => {
    setAssignedToUser(null);
    setUnassignedGlobalUser(e.target.checked);
  };

  const renderUnassignedDesCheckbox = () => {
    if (props.isCustomer) {
      return (
        <Row justify="start" style={{ marginTop: 10, paddingLeft: 5 }}>
          <Checkbox name="unassignedGlobalUser" checked={unassignedGlobalUser} onChange={handleUnassignedGlobalUserToggle}>
            Unassigned DES
          </Checkbox>
        </Row>
      );
    }
    return null;
  };

  return (
    <Modal title="Select Assign to" open={props.isModalOpen} width={"600px"} onCancel={() => onCancel(false)} onOk={handleAssignTo}>
      <Row>
        <DebounceSelect
          value={assignToUser}
          placeholder="Select user"
          fetchOptions={fetchUserList}
          onChange={(newValue) => {
            setAssignedToUser(newValue as any);
          }}
          style={{ width: "100%" }}
          disabled={unassignedGlobalUser}
        />
      </Row>
      {renderUnassignedDesCheckbox()}
    </Modal>
  );
}

export default AssignToModal;
