import React, { useContext } from "react";

import { FeatureFlags } from "../hooks/featureFlagsProvider";
import { explodeFileName, formatShortDate, timeSpent } from "../utils";
import { Attachment } from "../store/invoicesSlice";
import { Email } from "../types";

interface Props {
  email: Email;
  invoice: Attachment;
  isInvoice: boolean;
}

function EmailInfo({ email, invoice, isInvoice }: Props) {
  const { features } = useContext(FeatureFlags);

  if (isInvoice) {
    if (!invoice) return null;
    if (!invoice.finalizedData) return null;

    return (
      <>
        {features?.dynamicDataInfo.map((r, index) => {
          if ((r.dataType === "date" || r.dataType === "dateLocal") && invoice?.finalizedData[r.dataKey]) {
            let date = invoice?.finalizedData[r.dataKey];
            let isLocal = r.dataType === "dateLocal";
            let content;
            if (date) {
              content = <span className="fw-800">{formatShortDate(date, true, isLocal)} - <b>{timeSpent(date, isLocal)}</b></span>
            }
            else {
              content = <span className="fw-800"></span>
            }
            return (
              <p className="p-5px" key={index}>
                <span>{r.dataLabel}</span>:{" "}
                {content}
              </p>
            );
          } else if (r.dataType === "filename" && invoice[r.dataKey]) {
            let data = invoice[r.dataKey];
            const filename = explodeFileName(data);
            if (filename) {
              data = filename.filename;
            }
            return (
              <p className="p-5px" key={index}>
                <span>{r.dataLabel}</span>: <span className="fw-800">{data}</span>
              </p>
            );
          } else if (r.dataType === "emailStatus" && email[r.dataKey]) {
            return (
              <p className="p-5px" key={index}>
                <span>{r.dataLabel}</span>: <span className="fw-800">{email[r.dataKey]}</span>
              </p>
            );
          } else if (r.dataType === "string" && invoice?.finalizedData[r.dataKey]) {
            return (
              <p className="p-5px" key={index}>
                <span>{r.dataLabel}</span>: <span className="fw-800">{invoice?.finalizedData[r.dataKey]}</span>
              </p>
            );
          } else {
            return null;
          }
        })}
      </>
    );
  } else {
    return (
      <>
        {features?.dynamicDataInfo.map((r, index) => {
          if (r.dataType === "date" || r.dataType === "dateLocal") {
            let date = email[r.dataKey];
            let isLocal = r.dataType === "dateLocal";
            let content;
            if (date) {
              content = <span className="fw-800">{formatShortDate(date, true, isLocal)} - <b>{timeSpent(date, isLocal)}</b></span>
            }
            else {
              content = <span className="fw-800"></span>
            }
            return (
              <p className="p-5px" key={index}>
                <span>{r.dataLabel}</span>:{" "}
                {content}
              </p>
            );
          } else {
            return (
              <p className="p-5px" key={index}>
                <span>{r.dataLabel}</span>: <span className="fw-800">{email[r.dataKey]}</span>
              </p>
            );
          }
        })}
      </>
    );
  }
}

export default EmailInfo;
