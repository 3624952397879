import { DateTime } from 'luxon';
import { Attachment, FinalizedData, SalesForceField, checkPotentialDuplicate } from "../store/invoicesSlice";
import { notification } from 'antd';
import store from '../store';

export const SystemUserId: string = "00000000-0000-0000-0000-000000000000";
export const SystemUserName: string = "System User";
export const DatetimeFormat: string = "MM/dd/yyyy HH:mm:ss";
export const DateFormat: string = "MM/dd/yyyy";
export const zone: string = 'utc';
export const StatusList: StatusObj[] = [
  {
    label: "New",
    id: 5,
    showInFilter: true,
    key: "new",
    color: "#0984e3",
  },
  {
    label: "Processing",
    id: 4,
    showInFilter: true,
    key: "processing",
    color: "#0984e3",
  },
  {
    label: "Needs Review",
    id: 2,
    showInFilter: true,
    key: "needsReview",
    color: "#0984e3",
  },
  {
    label: "Completed",
    id: 1,
    showInFilter: true,
    key: "completed",
  },
  {
    label: "Salesforce Upload Failure",
    id: 3,
    showInFilter: true,
    key: "error",
    color: "#0984e3",
  },
];

export interface StatusCounts {
  key: string;
  count: number;
  percentage: number;
}

export interface StatusObj {
  id: number;
  label: string;
  showInFilter: boolean;
  key: string;
  displayOrder?: number;
  color?: string;
  hideCounter?: boolean;
}

export function ObjectToQueryParam(params: Object): string {
  if (params) {
    return (
      Object.keys(params)
        //@ts-ignore
        .filter((key) => params[key])
        //@ts-ignore
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join("&")
    );
  }
}

function parseDate(dateString: string, local = false) {
  let offset = DateTime.local().offset;
  let date = DateTime.fromISO(dateString?.replace(/pm/gi, ''), { zone }).setZone(offset);
  if (date.invalid) date = DateTime.fromFormat(dateString?.replace(/pm/gi, ''), DatetimeFormat, { zone }).setZone(offset);
  if (date.invalid) date = DateTime.fromFormat(dateString.replace(/pm/gi, ''), DateFormat);

  if (local) {
    date = DateTime.fromISO(dateString?.replace(/pm/gi, ''));
    if (date.invalid) date = DateTime.fromFormat(dateString?.replace(/pm/gi, ''), DatetimeFormat);
  }

  return date;
}

export function formatDate(dateString: string, bold: boolean = false, local = false) {
  if (dateString) {
    let date = parseDate(dateString, local);
    if (date.year === 0) return ``;
    return `${date.toFormat("MM-dd-yyyy h:mm:ss a")} - ${bold ? '<b>' : ''} ${date.toRelative()} ${bold ? '</b>' : ''}`;
  } else {
    return ``;
  }
}

export function formatShortDate(dateString: string, showHours = false, local = false) {
  if (dateString) {
    let date = parseDate(dateString, local);
    if (date.year === 0) return ``;
    if (showHours) return `${date.toFormat("MM-dd-yyyy h:mm:ss a")}`;
    return `${date.toFormat("MM-dd-yyyy")}`;
  } else {
    return ``;
  }
}

export function timeSpent(recordDate: Date | string, local = false): string {
  let date = DateTime.fromISO(recordDate, { zone });
  if (date.invalid) date = DateTime.fromFormat(recordDate, DatetimeFormat, { zone });

  if (local) {
    date = DateTime.fromISO(recordDate);
    if (date.invalid) date = DateTime.fromFormat(recordDate, DatetimeFormat);
  }

  if (date.isValid) {
    return date.toRelative();
  }
  return "";
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return [s4(), s4(), "-", s4(), "-", s4(), "-", s4(), "-", s4(), s4(), s4()].join("").toLowerCase();
}

export const getColorByStatusName = (statusName: string): string => {
  const status = StatusList.find((s) => s.label === statusName);
  if (!status || !status.color) return "#000";

  return status.color;
};

export const getStatusById = (id: number): StatusObj => {
  return StatusList.find((s) => s.id === id);
};

export const getStatusByLabel = (label: string): StatusObj => {
  return StatusList.find((s) => s.label.toLowerCase() === label.toLowerCase());
};

export const explodeFileName = (str: string) => {
  const res = str?.split("[!]");
  const filename = res[res.length - 1].split("[:]");
  return {
    emailId: res ? res[0] : "",
    email: res ? res[1] : "",
    received_at: res ? formatDate(res[2]) : "",
    filename: res && filename ? filename[filename.length - 1] : "",
  };
};

export const saveJson = (key: string, json: any) => {
  return sessionStorage.setItem(key, JSON.stringify(json));
};

export const getJson = (key: string) => {
  return JSON.parse(sessionStorage.getItem(key)) || [];
};

export const wrapWithBrackets = (str: string) => (str ? ` (${str})` : "");

export const parseLibraryDate = (dateStr: any) => {
  if (!dateStr) return undefined;
  try {
    const parsed = new Date(dateStr);

    const date = DateTime.fromJSDate(parsed).setZone('UTC');

    return date.toFormat('ccc LLL dd yyyy HH:mm:ss');
  } catch (e) {
    console.error("cannot parse");
  }
  return undefined;
};

export const mapStatusCounts = (payload: any): StatusCounts[] => {
  const counts: StatusCounts[] = [];
  if (payload) {
    const parentKeys = Object.keys(payload);
    parentKeys.forEach(pk => {
      let count: StatusCounts = {
        key: pk,
        count: payload[pk].count,
        percentage: payload[pk].percentage
      };
      counts.push(count);
    });
  }

  return counts;
};

export const formatAsUSD = (value: number | string): string => {
  if (!value) {
    return '-';
  }

  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  if (isNaN(numberValue) || numberValue === 0) {
    return '-';
  }

  const formattedValue = numberValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return formattedValue;
};

export const delay = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const getFileName = (filename: string) => {
  if (filename) {
    const match = filename.match(/.*\[!](.+)$/);
    return match ? match[1] : null;
  }
};

export const getAttachmentInfo = (attachment: Attachment, totalCalculated, list = true, index = null) => {
  const total = totalCalculated || attachment.finalizedData.invoiceTotalCalculated;
  const assetLocation = attachment.finalizedData.assetLocation || "-";
  const vendorName = attachment.finalizedData.vendor || "-";

  const assetName = attachment.finalizedData.asset || attachment.finalizedData.assetName;
  const assetNameParts = assetName?.split(' - ');
  const serialNumber = assetNameParts?.length ? assetNameParts[0] : "-";

  if (list) {
    return [
      { label: 'Serial number', text: serialNumber, key: "serialNumber" },
      { label: 'Location', text: assetLocation, key: "assetLocation" },
      { label: 'Vendor', text: vendorName, key: "vendorName" },
      { label: 'Total (Calculated)', text: total, key: "total" },
    ];
  }

  return {
    key: index,
    serialNumber,
    assetLocation,
    vendorName,
    total
  };
};

export const getInvoiceTotalCalculated = (formFields: SalesForceField[], features): number => {
  if (formFields.length) {
    const fields = [];
    for (let field of features.invoiceTotalValue.fields) {
      fields.push(parseFloat(formFields.filter((f) => f.id === field)[0]?.value) || 0.0);
    }

    let tax = 0;
    for (let taxField of features.invoiceTotalValue.taxFields) {
      const tf = formFields.find((d) => d.id === taxField);
      const reqField = formFields.find((e) => e.id === tf.enableIfField);
      if (tf.enableIfValue.includes(reqField?.value)) {
        tax += parseFloat(formFields.find((f) => f.id === taxField)?.value) || 0.0; // eslint-disable-line -- tax variable is used on eval expression below
      }
    }

    const total = eval(features.invoiceTotalValue.expression); // eslint-disable-line -- eval is used to calculate total
    return parseFloat(total);
  }
};

export const convertFieldsToFinalizedData = (data: SalesForceField[]): FinalizedData => {
  const finalizedData = {};

  data.forEach((e) => {
    if (e?.readOnly) return;
    if (e?.id === "salesForceWorkOrder" && e?.value === e?.default) return;
    finalizedData[e.id] = e?.value !== null ? e?.value : "";
    if (e?.lookupURL && e?.[`${e.id}Id`]) finalizedData[`${e.id}Id`] = e?.[`${e.id}Id`];
  });

  return finalizedData as FinalizedData;
};

export const areAllDuplicateDetectionFieldsFilled = (duplicateDetectionFields: any[], data: any) => {
  const cleanedDuplicateDetectionFields = duplicateDetectionFields.map(({ dbPropertyName }) => dbPropertyName?.replace("Id", ""));
  return cleanedDuplicateDetectionFields.every((duplicateDetectionField) => {
    const i = data.findIndex((d) => d.id === duplicateDetectionField);
    return i > 0 ? data[i].value : false;
  });
};

export const detectPotentialDuplicateBeforeSubmit = (attachmentId: string, fields: SalesForceField[], data: FinalizedData, duplicateDetectionFields: any[]) => {
  if (areAllDuplicateDetectionFieldsFilled(duplicateDetectionFields, fields)) {
    const finalizedFields: FinalizedData = convertFieldsToFinalizedData(fields);
    const finalizedData: FinalizedData = { ...finalizedFields, plainFilename: data.plainFilename };

    store.dispatch(checkPotentialDuplicate({ attachmentId, finalizedData })).then((res) => {
      if (res.payload?.length > 0) {
        notification.warning({
          message: "Potential Duplicates Detected",
          description: `We have identified ${res.payload?.length} potential duplicate(s). Please review the updated list to ensure accuracy.`,
          duration: 5,
        });
      }
    });
  }
};

export const formatDecimal = (value, decimalPlaces: number = 2): string => {
  if (value && !isNaN(value)) {
    var formattedNumber = parseFloat(value).toFixed(decimalPlaces);
    if (formattedNumber.endsWith('.00') || formattedNumber.endsWith(',00')) {
      return parseInt(formattedNumber, 10).toString();
    } else {
      return formattedNumber;
    }
  }

  return value;
};

export const AmountsDifferenceIndicatorColor = (amountDifferenceIndicator, entity, tolerance): string => {
  const red = "#FF6B6B";
  const green = "#4CAF50";
  const yellow = "#FCC823";

  const poAmount = entity?.finalizedData?.poAmount;
  const invoiceAmount = entity?.finalizedData?.invoiceAmount;

  const validPoAmount = poAmount != null && poAmount >= 0;
  const validInvoiceAmount = invoiceAmount != null && invoiceAmount >= 0;

  if (validPoAmount && validInvoiceAmount) {
    if (amountDifferenceIndicator <= 0) {
      return green;
    }

    if (amountDifferenceIndicator <= tolerance) {
      return yellow;
    }
  }

  return red;
};