import React, { useEffect, useState } from "react";

import Search, { SearchProps } from "antd/lib/input/Search";
import { Button, Col, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";

import { Customer } from "../store/customersSlice";
import AssignToModal from "./AssignToModal";

interface UnassignedCustomersProps {
  customers: Customer[];
  loading: boolean;
  onAssignCustomers: (userId: string, customerId: React.Key[]) => void;
}

function UnassignedCustomers(props: UnassignedCustomersProps) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setFilteredCustomers(props.customers);
  }, [props.customers]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const columns: ColumnsType<Customer> = [
    {
      title: "Customer Name",
      dataIndex: "name",
    },
  ];

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setFilteredCustomers(props.customers.filter((x) => x.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
  };

  const handleAssignTo = () => {
    setIsModalOpen(true);
  };

  const onClosePopup = (refresh: boolean, userId: string = '') => {
    if (userId.length > 0) {
      props.onAssignCustomers(userId, selectedRowKeys);
    }
    setIsModalOpen(false);
    setSelectedRowKeys([]);
  }

  return (
    <>
      <Row className="mb-20px">
        <Col span={24}>
          <Search placeholder="Search Customers" allowClear enterButton="Search" size="large" onSearch={onSearch} />
        </Col>
      </Row>
      <Row className="mb-20px">
        <Col span={24}>
          <Button type="default" disabled={selectedRowKeys.length === 0} onClick={handleAssignTo}>
            Assign To
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={props.loading}
            rowKey={(e) => e.id}
            columns={columns}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys,
              ...rowSelection,
            }}
            dataSource={filteredCustomers}
            pagination={false}
          />
        </Col>
      </Row>

      <AssignToModal
          isModalOpen={isModalOpen}
          emails={[]}
          invoices={[]}
          isCustomer={true}
          closePopup={onClosePopup}
        ></AssignToModal>
    </>
  );
}

export default UnassignedCustomers;
