import React, { useCallback, useContext, useEffect, useState } from "react";
import { Pagination, Table, Tooltip } from "antd";
import { CopyOutlined, DollarCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { isArray } from "lodash";
import { MdAttachMoney, MdNotes } from "react-icons/md";
import { ColumnModel, DocTypeRename, FeatureFlags, StatusRename } from "../hooks/featureFlagsProvider";
import { RootState, useAppSelector } from "../store";
import UserPresenceIcon from "./UserPresenceIcon";
import { explodeFileName, timeSpent, formatShortDate, formatDecimal, AmountsDifferenceIndicatorColor } from "../utils";
import { Attachment, fetchAllInvoices, fetchInvoiceCounts } from "../store/invoicesSlice";
import { Filter } from "../pages/Dashboard";
import { IPresenceUser } from "../types";

interface Props {
  filter: Filter | null;
  onFilterChange: (key: keyof Filter, value?: number | string) => void;
  selectedEmails: React.Key[];
  onSelectionChange: (selectedRows: React.Key[]) => void;
}

function InvoicesTable({ filter, onFilterChange, selectedEmails, onSelectionChange }: Props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const dispatch = useDispatch();
  const presenceUsers: IPresenceUser[] = useAppSelector((state) => state.signalRHub.presenceUsers);
  const urlParams = new URLSearchParams(window.location.search);
  const [columns, setColumns] = useState([]);
  const { data, loading, totalCount, error } = useSelector((state: RootState) => state.invoices);
  const { features } = useContext(FeatureFlags);
  const statusRename: StatusRename[] = features.statusRename;
  const docTypeRename: DocTypeRename[] = features.docTypeRename;
  const amountIndicatorTolerance: number = features.amountIndicatorTolerance;
  const history = useHistory();

  const handleRecord = useCallback(
    (c: ColumnModel, entity: Attachment) => {
      let data = "";
      if (entity?.finalizedData) {
        data = entity.finalizedData[c.dbPropertyName];
      }
      let date = "";

      switch (c.renderType) {
        case "icons":
          switch (c.dbPropertyName) {
            case "amountDifferenceIndicator":
              return (
                <div style={{ textAlign: "center" }}>
                  <DollarCircleFilled
                    style={{
                      color: AmountsDifferenceIndicatorColor(data, entity, amountIndicatorTolerance),
                      fontSize: "20px",
                    }}
                  />
                </div>
              );
            case "userNotes":
              return data && data.length > 0 ? <Tooltip title={data}>{<MdNotes style={{ fontSize: "large" }} />}</Tooltip> : null;
            default:
              let potentialDups = entity.finalizedData.potentialDuplicateInvoiceIds?.map((dup) => explodeFileName(dup).filename).join(", ") || "";

              const presenceUsersForEntity = presenceUsers.filter((users) => users.entityId === entity.id);
              return (
                <>
                  {entity.finalizedData?.potentialDuplicateInvoiceIds?.length ? (
                    <Tooltip title={`Potential duplicates: ${potentialDups}`}>
                      <CopyOutlined />
                    </Tooltip>
                  ) : null}
                  <UserPresenceIcon presenceUsers={presenceUsersForEntity}></UserPresenceIcon>
                </>
              );
          }
        case "filename":
          if (entity && entity[c.dbPropertyName]) {
            data = entity[c.dbPropertyName];
          }
          const filename = explodeFileName(data);
          if (filename) {
            return filename.filename;
          }
          return data;
        case "status":
          if (statusRename && isArray(statusRename)) {
            const status = statusRename.find((s) => s.status === data);
            if (status) {
              return status.displayAs ?? data;
            }
          }
          return data;
        case "text":
          if (c.dbPropertyName === "docType") return docTypeRename.find((sr: any) => sr.docType === data)?.displayAs ?? data;
          return data;
        case "timeSpent":
          return calculateTimeSpent(entity);
        case "date":
          date = formatShortDate(data, true);
          if (date)
            return (
              <div>
                {date} - <b>{timeSpent(data)}</b>
              </div>
            );
          return "";
        case "dateLocal":
          date = formatShortDate(data, true, true);
          if (date)
            return (
              <div>
                {date} - <b>{timeSpent(data, true)}</b>
              </div>
            );
          return "";
        case "shortdate":
          date = formatShortDate(data);
          if (date) return <div>{date}</div>;
          return "";
        case "decimal":
          return formatDecimal(data);
      }

      return "";
    },
    [presenceUsers, statusRename, docTypeRename, amountIndicatorTolerance]
  );

  const setHeaderIcon = useCallback((dbPropertyName: string, label: string) => {
    switch (dbPropertyName) {
      case "userNotes":
        return (
          <div aria-label={label}>
            <MdNotes style={{ fontSize: "large" }} />
          </div>
        );
      case "amountDifferenceIndicator":
        return (
          <div aria-label={label}>
            <MdAttachMoney style={{ fontSize: "large" }} />
          </div>
        );
      case "potentialDuplicates":
      default:
        return <></>;
    }
  }, []);

  useEffect(() => {
    const displayCols = [];
    const columns = features?.displayColumns?.filter((c) => c.displayOrder >= 0);

    if (columns) {
      columns.sort((a, b) => a.displayOrder - b.displayOrder);
      columns.forEach((c: ColumnModel) => {
        let column = {
          title: c.renderType === "icons" ? setHeaderIcon(c.dbPropertyName, c.label) : c.label,
          dataIndex: c.dbPropertyName,
          sorter: c.isSortable,
          width: c.width,
          render: (_, record) => handleRecord(c, record),
          onHeaderCell: () => ({
            "aria-label": c.label,
            id: c.dbPropertyName,
          }),
        };

        if (c.dbPropertyName === "poNumber" || c.dbPropertyName === "invoiceNumber") {
          column.width = "8%";
        }

        if (c.showIfStatus === undefined || c.showIfStatus === filter?.status) {
          displayCols.push(column);
        }
      });
    }

    setColumns(displayCols);
  }, [features, filter, handleRecord, setHeaderIcon]);

  useEffect(() => {
    const refreshAfterSubmit = localStorage.getItem(`refreshAfterSubmit`);
    const controller = new AbortController();

    if (refreshAfterSubmit && filter) {
      dispatch(fetchInvoiceCounts({ controller }));
      dispatch(fetchAllInvoices({ filter, controller, refresh: true }));
      localStorage.removeItem(`refreshAfterSubmit`);
    }

    return () => {
      controller.abort();
    };
  }, [filter, dispatch]);

  const onRowClick = (record: Attachment, rowIndex?: number) => {
    const url = `/invoice/${features.IsDetailsMultiFile ? record.emailId : record.id}?isInvoice=${!features.IsDetailsMultiFile}`;
    history.push(url, { fileName: record.filename, selectedId: record.id });
  };

  const handleChange = (pagination, filters, sorter) => {
    if (sorter.order && sorter.order === "ascend") {
      sessionStorage.setItem(`sortDirection`, sorter.order.slice(0, 3));
      onFilterChange("sortDirection", sorter.order.slice(0, 3));
    }
    if (sorter.order && sorter.order === "descend") {
      sessionStorage.setItem(`sortDirection`, sorter.order.slice(0, 4));
      onFilterChange("sortDirection", sorter.order.slice(0, 4));
    }
    if (sorter.field) {
      sessionStorage.setItem(`sortColumn`, sorter.field.toString());
      onFilterChange("sortColumn", sorter.field);
    }
  };

  const pageIndex = +urlParams.get("page") || 1;
  const pageCount = +urlParams.get("count") || +sessionStorage.getItem(`count`) || 20;
  let beginIndex = (data.length ?? 0) > 0 ? (pageIndex - 1) * pageCount + 1 : 0;
  let endIndex = beginIndex > 0 ? beginIndex + (data.length ?? 0) - 1 : 0;
  if (endIndex > totalCount) endIndex = totalCount;

  const calculateTimeSpent = (record: Attachment) => {
    const recordDate = record?.finalizedData?.statusLastChangeAt;
    if (recordDate) {
      return timeSpent(recordDate);
    } else {
      return "";
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      onSelectionChange(selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  useEffect(() => {
    setSelectedRowKeys(selectedEmails);
  }, [selectedEmails]);

  const TableErrorIcon = (
    <div style={{ textAlign: "center", margin: 10 }}>
      <ExclamationCircleOutlined style={{ fontSize: 50 }} />
      <p style={{ margin: 0, fontSize: 20 }}>Oops An Error Occurred</p>
    </div>
  );

  return (
    <>
      <Table
        className="custom-table"
        onRow={(record, rowIndex) => ({
          onClick: (e) => onRowClick(record, rowIndex),
        })}
        loading={loading}
        rowKey={(e) => e.id}
        columns={columns}
        rowSelection={
          features.isAssignToEnabled
            ? {
                type: "checkbox",
                selectedRowKeys,
                ...rowSelection,
              }
            : null
        }
        dataSource={data}
        locale={{ emptyText: error ? TableErrorIcon : null }}
        pagination={
          features.pageLayout.useDefaultLayout && {
            onChange: (page, pageSize) => {
              const prevPageSize = sessionStorage.getItem(`count`);
              sessionStorage.setItem(`count`, pageSize.toString());

              onFilterChange("page", page);

              if (prevPageSize !== pageSize.toString()) onFilterChange("count", pageSize);
            },

            total: totalCount,
            pageSize: +urlParams.get("count") || +sessionStorage.getItem(`count`) || 20,
            showSizeChanger: true,
            current: +urlParams.get("page") || 1,
          }
        }
        onChange={handleChange}
      />

      {!features.pageLayout.useDefaultLayout && (
        <>
          <div className="custom-pager pt-20px fs-11px">
            Showing results {beginIndex} to {endIndex} out of {totalCount ?? 0}
          </div>
          <Pagination
            className="custom-pager pt-10px"
            total={totalCount}
            size="small"
            showSizeChanger={false}
            current={pageIndex}
            pageSize={pageCount}
            onChange={(page, pageSize) => {
              const prevPageSize = sessionStorage.getItem(`count`);
              sessionStorage.setItem(`count`, pageSize.toString());

              onFilterChange("page", page);

              if (prevPageSize !== pageSize.toString()) onFilterChange("count", pageSize);
            }}
          />
        </>
      )}
    </>
  );
}

export default InvoicesTable;
