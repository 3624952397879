import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, List, Modal, Radio, RadioChangeEvent, Space, Spin, Typography } from "antd";

import { ExclamationCircleFilled, CopyOutlined } from "@ant-design/icons";
import styled from "styled-components";

import { breakpoint } from "../theme";

import { useAppDispatch, useAppSelector } from "../store";
import { fetchAuditLogByAttachmentId } from "../store/auditLogSlice";
import {
  Attachment,
  fetchSalesForceFields,
  bulkStatusUpdate,
  setInvoiceData,
  updateInvoice,
  resetCurrentAttachment,
  fetchInvoice,
} from "../store/invoicesSlice";

import useSignalR from "../hooks/useSignalr";
import { DetailPageSection, FeatureFlags } from "../hooks/featureFlagsProvider";

import { IPresenceUser } from "../types";

import AttachmentInfoTable from "../components/AttachmentInfoTable";
import AttachmentInfoPopover from "../components/AttachmentInfoPopover";
import UserPresenceIcon from "../components/UserPresenceIcon";
import InvoiceFields from "../components/InvoiceFields";
import HtmlRenderer from "../components/HtmlRenderer";
import { fetchEmail, fetchInvoices, resetCurrentEmail } from "../store/emailsSlice";
import BlankCard from "../components/BlankCard";
import EmailInfo from "../components/EmailInfo";
import DocumentRenderer from "../components/DocumentRenderer";
import AuditLog from "../components/AuditLog";
import AdditionalDocumentsSection from "../components/AdditionalDocumentsSection";
import DocumentVersionsSection from "../components/DocumentVersionsSection";
import AttachmentIcon from "../components/AttachmentIcon";
import PotentialDuplicatesSection from "../components/PotentialDuplicatesSection";
import RelatedDocumentsSecion from "../components/RelatedDocumentsSecion";

const { confirm, info, warning } = Modal;
const { Text } = Typography;

interface Props {
  envConfig: any;
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  overflow: hidden;
  @media only screen and ${breakpoint.device.sm} {
    flex-direction: row;
  }
`;

function AttachmentPage({ envConfig }: Props) {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { features } = useContext(FeatureFlags);
  const controlSize = features?.pageLayout.controlsSize ?? "large";

  const user = useAppSelector((state) => state.user);
  const roles = user?.roles;

  const search = useLocation().search;
  const isInvoice: boolean = useMemo(() => new URLSearchParams(search).get("isInvoice") === "true", [search]);

  const currentEmail = useAppSelector((state) => state.emails.current);
  const currentInvoice = useAppSelector((state) => state.invoices.current);

  const [currentVersionFilename, setCurrentVersionFilename] = useState<string | null>(null);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState<Attachment | null>(null);

  const [readOnly, setReadOnly] = useState(true);
  const prevReadOnly = useRef<boolean | undefined>();

  const invoices = useAppSelector((state) => state.emails.invoices);

  const loadingInvoices = useAppSelector((state) => state.invoices.loading);
  const loadingEmail = useAppSelector((state) => state.emails.loading);
  const loadingEmailInvoices = useAppSelector((state) => state.emails.loadingInvoices);

  const isLoading = loadingInvoices || loadingEmail || loadingEmailInvoices;

  const presenceUsers: IPresenceUser[] = useAppSelector((state) => state.signalRHub.presenceUsers);

  const attachment = React.useMemo(() => {
    if (selectedAttachmentId) {
      const inv = invoices.find((e) => e.id === selectedAttachmentId);
      if (inv != null) {
        return inv;
      }
    }

    if (isInvoice && currentInvoice?.id) {
      return currentInvoice;
    }

    const { state } = window?.history?.state || window?.history;
    if (state?.selectedId) {
      const inv = invoices.find((i) => i.id === state.selectedId);
      if (inv != null) {
        return inv;
      }
    }

    return invoices?.length ? invoices[0] : null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isInvoice, selectedAttachmentId]);

  const renderedFile = currentVersionFilename || attachment?.filename;

  const initializeAttachment = () => {
    dispatch(fetchSalesForceFields());

    if (isInvoice) {
      dispatch(fetchInvoice(id));
      return;
    }

    dispatch(fetchEmail(id));
    dispatch(fetchInvoices(id));
  };

  // eslint-disable-next-line
  useEffect(initializeAttachment, [id]);

  useEffect(() => {
    if (isInvoice && currentInvoice?.emailId) {
      dispatch(fetchEmail(currentInvoice.emailId));
    }
  }, [dispatch, isInvoice, currentInvoice?.emailId]);

  useEffect(() => {
    return () => {
      dispatch(resetCurrentAttachment());
      dispatch(resetCurrentEmail());
    };
  }, [dispatch]);

  useEffect(() => {
    if (attachment?.id && features?.enableAuditLogging) {
      dispatch(fetchAuditLogByAttachmentId({ id: attachment.id }));
    }
    // eslint-disable-next-line
  }, [attachment]);

  useEffect(() => {
    if (attachment?.finalizedData?.versions?.length) {
      const { versions } = attachment.finalizedData;
      const latestVersion = versions[versions.length - 1];
      setCurrentVersionFilename(latestVersion);
    }
    // eslint-disable-next-line
  }, [attachment?.finalizedData?.versions?.length]);

  const hasFullAccessRole = features.fullAccessRolesNames.some((role) => roles.includes(role));
  const hasReadOnlyAccess = roles.length > 0 ? !hasFullAccessRole : true;
  const initialReadOnlyValue = features.isFullAccessRoles ? hasReadOnlyAccess : false;

  const presenceUsersForEntity = presenceUsers.filter((users) => users.entityId === id);
  const firstPresenceUser = presenceUsersForEntity.find((user) => !user.readOnly);

  const disableState = features?.disableStateStatus.some((status) => attachment?.finalizedData?.status === status);

  useEffect(() => {
    if (features.presenceLock && firstPresenceUser && initialReadOnlyValue === false) {
      const isFirstPresenceUserNotMe = firstPresenceUser.userName !== user.userName;

      if (isFirstPresenceUserNotMe) {
        warning({
          title: "Form locked",
          content: `This record is currently being processed by: ${firstPresenceUser.userName}`,
        });

        setReadOnly(true);
        return;
      }
    }

    setReadOnly(initialReadOnlyValue);
    // eslint-disable-next-line
  }, [firstPresenceUser?.userName]);

  useEffect(() => {
    if (features.presenceLock) {
      if (prevReadOnly.current === true && readOnly === false) {
        initializeAttachment();
      }

      prevReadOnly.current = readOnly;
    }
    // eslint-disable-next-line
  }, [readOnly]);

  useSignalR({
    envConfig,
    id: features.IsDetailsMultiFile ? attachment?.id : id,
    readOnly: initialReadOnlyValue,
  });

  const getConfirmContent = (message) => {
    const messageParts = message.split(": ");
    const urls = messageParts[1].split(",");
    return (
      <div>
        <p>{messageParts[0]}</p>
        <ul>
          {urls.map((url, index) => (
            <li key={index}>
              <a
                key={index}
                target="_blank"
                style={{ textDecoration: "underline", fontWeight: "bold" }}
                href={`${window.location.origin}/invoice/${url}`}
                rel="noreferrer"
              >
                {url}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  function showConfirmSubmit(data, message) {
    confirm({
      title: "Are you sure you want to submit?",
      okText: "Yes",
      icon: <ExclamationCircleFilled />,
      content: getConfirmContent(message),
      onOk() {
        dispatch(updateInvoice({ id: attachment.id, finalizedData: data, ignore: true }));
      },
      onCancel() {
        dispatch(setInvoiceData(data));
      },
    });
  }

  function renderUserPresenceIcon() {
    const presenceUsersForEntity = presenceUsers.filter((users) => users.entityId === id);
    return presenceUsersForEntity.length ? (
      <p className="p-5px">
        <UserPresenceIcon presenceUsers={presenceUsersForEntity} />
      </p>
    ) : null;
  }

  const canBeMovedToNeedsReview = () => {
    return invoices.filter(
      (invoice) =>
        (invoice.finalizedData.status === "Rejected" && invoice.finalizedData.rejectedReason === "Awaiting Additional Documents") ||
        invoice.finalizedData.status === "Issues"
    );
  };

  const showMovedToNeedsReviewModal = () => {
    const invoicesToMove = canBeMovedToNeedsReview();
    info({
      title: "This action will only move invoices that are in status 'Corrections Needed' or 'Rejected' with 'Awaiting Additional Docs'.",
      okText: "Continue",
      closable: true,
      okCancel: true,
      content: (
        <div>
          <List
            header="List of invoices that match this criteria:"
            dataSource={invoicesToMove}
            renderItem={(item) => (
              <List.Item style={{ padding: "2px 0", display: "flex", justifyContent: "space-between" }}>
                <Text style={{ fontSize: "12px", marginRight: "10px" }}>{` ${item.finalizedData.plainFilename} ${
                  item.finalizedData.customerName ? "- " + item.finalizedData.customerName : ""
                } ${item.finalizedData.originalAttachmentId ? "- (Duplicate-" + item.finalizedData.duplicateNumber + ")" : ""}`}</Text>
              </List.Item>
            )}
          />
        </div>
      ),
      onOk() {
        const ids = invoicesToMove.map((invoice) => invoice.id);
        dispatch(bulkStatusUpdate({ ids, status: "Needs Review" })).then(initializeAttachment);
      },
    });
  };

  const renderFile = (file) => {
    const item = (
      <Radio key={file?.id} value={file?.id}>
        <AttachmentIcon status={file.finalizedData.status}></AttachmentIcon>
        {file.finalizedData.originalAttachmentId && file.finalizedData.originalAttachmentId.length > 0 && <CopyOutlined />}
        {` ${file.finalizedData.plainFilename} ${file.finalizedData.customerName ? "- " + file.finalizedData.customerName : ""} ${
          file.finalizedData.originalAttachmentId ? "- (Duplicate-" + file.finalizedData.duplicateNumber + ")" : ""
        }`}
      </Radio>
    );

    if (features.showAttachmentPopover) {
      return (
        <AttachmentInfoPopover key={file.id} features={features} attachment={file}>
          {item}
        </AttachmentInfoPopover>
      );
    }

    return item;
  };

  const onSelectAttachment = (e: RadioChangeEvent) => {
    const id = e.target.value;
    const invoice = invoices.find((i) => i.id === id);
    if (invoice) {
      if (window.history.replaceState) {
        window.history.replaceState({ selectedId: id }, "", "");
      }
      setSelectedAttachmentId(invoice.id);
    }
  };

  /* Detail Page Sections Block */
  const renderSection = (section: DetailPageSection) => {
    if (!attachment?.id) {
      return (
        <BlankCard loading={true} key={section.key}>
          <></>
        </BlankCard>
      );
    }

    let title = section.title || "";
    let content = null;
    let style = {};

    const attachmentSelectionSection = (
      <>
        {!isInvoice && (
          <Spin spinning={isLoading}>
            <Radio.Group value={attachment?.id} key={attachment?.id} onChange={onSelectAttachment}>
              <Space direction="vertical">{[...invoices].map(renderFile)}</Space>
            </Radio.Group>
            {canBeMovedToNeedsReview().length > 0 && (
              <Button size="middle" style={{ marginTop: "20px" }} onClick={() => showMovedToNeedsReviewModal()}>
                Move Files To Needs Review
              </Button>
            )}
          </Spin>
        )}
      </>
    );

    const potentialDuplicatesSection = (
      <>
        <PotentialDuplicatesSection attachment={attachment} isAttachment={isInvoice}></PotentialDuplicatesSection>
        <RelatedDocumentsSecion attachment={attachment}></RelatedDocumentsSecion>
      </>
    );

    const attachmentMetadataSection = (
      <>
        <EmailInfo email={currentEmail} invoice={attachment} isInvoice={isInvoice} />
        {renderUserPresenceIcon()}
      </>
    );

    switch (section.key) {
      case "AttachmentMetadata":
        content = attachmentMetadataSection;
        break;
      case "EmailBody":
        content = <HtmlRenderer html={currentEmail?.body} inline={currentEmail.inline} envConfig={envConfig} />;
        break;
      case "AttachmentSelection":
        content = attachmentSelectionSection;
        break;
      case "AttachmentInfo":
        content = <AttachmentInfoTable features={features} attachments={invoices}></AttachmentInfoTable>;
        style = { padding: 0 };
        break;
      case "PotentialDuplicates":
        content = potentialDuplicatesSection;
        break;
      case "InputFields":
        content = (
          <Spin spinning={isLoading}>
            <InvoiceFields
              emailId={attachment.emailId}
              invoice={attachment}
              disabled={disableState || readOnly}
              onDuplicateError={(data, message) => showConfirmSubmit(data, message)}
              isInvoice={isInvoice}
            />
          </Spin>
        );
        break;
      case "DocumentVersions":
        content = (
          <Spin spinning={isLoading}>
            <DocumentVersionsSection
              attachment={attachment}
              isAttachment={isInvoice}
              renderedFile={renderedFile}
              changeVersion={(selectedVersion) => setCurrentVersionFilename(selectedVersion)}
              controlSize={controlSize}
              disabled={readOnly || disableState}
            ></DocumentVersionsSection>
          </Spin>
        );
        break;
      case "AdditionalDocuments":
        content = (
          <Spin spinning={isLoading}>
            <AdditionalDocumentsSection
              attachment={attachment}
              isAttachment={isInvoice}
              controlSize={controlSize}
              disabled={readOnly || disableState}
            ></AdditionalDocumentsSection>
          </Spin>
        );
        break;
      case "AuditLog":
        content = <AuditLog invoiceId={attachment.id} />;
        break;
      default:
        return null;
    }

    return (
      <BlankCard key={section.key} id={section.key} collapsible={section.collapsible} style={style} title={title}>
        {content}
      </BlankCard>
    );
  };

  return (
    <Row>
      <div style={{ height: `calc(100vh - ${features?.pageLayout.useDefaultLayout ? 50 : 120}px)`, flex: 1 }}>
        <DocumentRenderer src={renderedFile} key={renderedFile} envConfig={envConfig} />
      </div>
      <div style={{ height: `calc(100vh - ${features?.pageLayout.useDefaultLayout ? 50 : 120}px)`, overflowX: "hidden", flex: 1 }}>
        {features?.detailPageSections.map(renderSection)}
      </div>
    </Row>
  );
}

export default AttachmentPage;
