import React from "react";
import CustomerMapping from "../components/CustomerMapping";
import ExpenseSuppliers from "../components/ExpenseSuppliers";
import NotFound from "./NotFound";

interface Props {
    envConfig: any;
}

function Admin({ envConfig }: Props) {

    switch (envConfig?.appConfig?.theme) {
        case "tfs":
            return <CustomerMapping envConfig={envConfig} />;
        case "miner":
            return <ExpenseSuppliers envConfig={envConfig} />;
        default:
            return <NotFound />;
    }
}

export default Admin;