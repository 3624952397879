import { useState, useEffect } from "react";
import axios from "axios";
import { PublicClientApplication, Configuration } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

function useConfigLoader() {
  const [envConfig, setEnvConfig] = useState(null);
  const [authConfig, setAuthConfig] = useState(null);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);

  useEffect(() => {
    if (isConfigLoaded) {
      return;
    }

    const fetchConfig = async () => {
      try {
        const { data } = await axios.get(`${window.location.origin}/env.json`, {
          headers: {
            'Cache-Control': 'no-cache',
          },
        });

        const msalConfig: Configuration = {
          auth: {
            authority: data.auth.authority,
            clientId: data.auth.clientId,
            redirectUri: window.location.origin,
            postLogoutRedirectUri: "/",
          },
          cache: {
            storeAuthStateInCookie: isIE || isEdge || isFirefox,
          },
        };

        const msalInstance = new PublicClientApplication(msalConfig);

        setAuthConfig(msalInstance);
        setEnvConfig(data);
        setIsConfigLoaded(true);
      } catch (error) {
        console.error("Could not load application configuration.");
        console.error(error);
      }
    };

    fetchConfig();
  }, [isConfigLoaded]);

  return { isConfigLoaded, envConfig, authConfig };
};

export default useConfigLoader;
