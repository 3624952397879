import React, { useRef } from "react";
import dompurify from 'dompurify';
type Props = {
  html: string;
  inline: string[];
  envConfig: any;
};

//sort inline images in order to parse them correcty into email body
const sortImages = (imagesArr) => {
  return [...imagesArr]
    .map((el) => [el.replace("image00", "image").replace("~", ""), el])
    .sort((a, b) => a[0].localeCompare(b[0]))
    ?.map((e) => e[1]);
};

export default function HtmlRenderer({ html, inline, envConfig }: Props) {
  const ref = useRef<HTMLDivElement>();
  React.useEffect(() => {
    if (!inline?.length || !ref || !ref.current) return;
    const imgs = ref?.current?.querySelectorAll("img");
    const inlineImages = sortImages(inline);

    if (!inlineImages) return;

    imgs.forEach((img, i) => {
      const encodeLink = (link: string) =>
        `https://${envConfig.appConfig.storageAccountName}.blob.core.windows.net/inline/` +
        encodeURI(link).replaceAll("#", "%23") +
        envConfig.appConfig.sharedAccessToken;

        img.src = encodeLink(inlineImages[i]);
    });
  }, [envConfig, inline]);

  return (
    <div
      ref={ref}
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(html),
      }}
    ></div>
  );
}
