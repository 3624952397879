import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

export const searchUsers = async (search: string): Promise<any[]> => {
  if (search.length >= 3) {
    return axios.get(`graph-users/search?search=${search}`).then((res: any) => {
      if (res.status === 200) {
        const data = res.data;
        if (data.succeeded) {
          const list = data.data.map((d: any) => {
            return {
              key: d.id,
              label: d.displayName,
              value: d.id
            };
          });
          return list;
        } else {
          return [];
        }
      }
    });
  } else {
    return [];
  }
};

export const assignEmailToUser = async (emails: React.Key[], userId: string, userName: string): Promise<any[]> => {
  const body = {
    EmailGuids: emails,
    UserName: userName
  };
  return axios.post(`emails/${userId}/messagesassignuser`, body).then((res) => res.data);
};

export const assignInvoiceToUser = async (emails: React.Key[], userId: string, userName: string): Promise<any[]> => {
  const body = {
    AttachmentGuids: emails,
    UserName: userName
  };
  return axios.post(`invoices/${userId}/messagesassignuser`, body).then((res) => res.data);
};

const initialUserState = {
  loading: false,
  userName: {} as string,
  roles: [] as string[],
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUser(state, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
    setRoles(state, action: PayloadAction<string[]>) {
      state.roles = action.payload;
    },
  },
  extraReducers: {
  },
});

export const { setUser, setRoles } = userSlice.actions;
export default userSlice.reducer;
