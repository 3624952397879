import React, { useContext, useEffect, useState } from "react";
import { LogoutOutlined, LeftOutlined, DownOutlined, SettingOutlined, TableOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Col, Dropdown, MenuProps, Row, Space } from "antd";
import { useAccount, useMsal } from "@azure/msal-react";

import { FeatureFlags } from "../hooks/featureFlagsProvider";
import useConfigLoader from "./../hooks/useConfigLoader";
import { useAppSelector } from "../store";
import styled from "styled-components";

const StyledHeader = styled.header`
  height: 50px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
`;

function Header() {
  const { features } = useContext(FeatureFlags);
  const { envConfig } = useConfigLoader();
  const history = useHistory();
  const location = useLocation();
  const { instance, accounts } = useMsal();
  const isInvoicePage = location.pathname.includes("invoice");
  const [title, setTitle] = useState("");
  const account = useAccount(accounts[0]);
  const [logo, setLogo] = useState("");
  const roles = useAppSelector((state) => state.user?.roles);
  const hasAdmin = roles.includes('Admin');

  const gotoAdmin = () => {
    const url = `/admin`;
    history.push(url);
  };

  const gotoDashboard = () => {
    const url = `/`;
    history.push(url);
  };

  const isAdmin = location.pathname.includes("admin") || false;

  let items: MenuProps["items"] = hasAdmin ? 
  [
    {
      key: isAdmin ? "Dashboard" : "Admin",
      label: isAdmin ? "Dashboard" : "Admin",
    },
    {
      key: "Logout",
      label: "Logout",
    },
  ] :
  [
    {
      key: "Logout",
      label: "Logout",
    },
  ];

  const logout = () => {
    sessionStorage.removeItem("sortDirection");
    sessionStorage.removeItem("sortColumn");
    sessionStorage.removeItem("count");
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    window.history.pushState({ path: newurl }, "", newurl);
    instance.logoutRedirect();
  };

  const onClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "Logout":
        logout();
        break;
      case "Admin":
        gotoAdmin();
        break;
      case "Dashboard":
        gotoDashboard();
        break;
    }
  };

  useEffect(() => {
    setTitle(envConfig?.appConfig?.title ?? "Invoice Review");
    setLogo(envConfig?.appConfig?.theme);
  }, [envConfig]);

  if (features?.pageLayout.useCustomHeader) {
    return (
      <>
        <Row className="custom-header">
          <Col span={1} />
          <Col xs={5} md={4} lg={3} className="logo">
            <img src={`/assets/${logo}/header-logo.png`} alt={`${title}`} />
          </Col>
          <Col xs={5} md={4} lg={3} className="title fs-16px fw-800 text-start">
            OCR
          </Col>
          <Col xs={12} md={14} lg={16}>
            <Dropdown menu={{ items, onClick }} trigger={["click"]} className="user-menu" overlayClassName="user-drop">
              <Button>
                <Space>
                  <FontAwesomeIcon icon={faCircleUser} size="lg" />
                  {account.name ?? ""}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Col>
          <Col span={1} />
        </Row>
        {isInvoicePage && (
          <Row className="p-20px fw-800 bg-white-100">
            <Col span={24}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (isInvoicePage && history.action !== "POP") {
                    return history.goBack();
                  }
                  return history.push("/");
                }}
              >
                <LeftOutlined /> Back
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  } else {
    return (
      <StyledHeader className="header">
        <div
          style={{ cursor: "pointer" }}
          className="title"
          onClick={() => {
            if (isInvoicePage && history.action !== "POP") {
              return history.goBack();
            }
            return history.push("/");
          }}
        >
          {isInvoicePage && <LeftOutlined />} {title}
        </div>
        <div className="title">
          {!isAdmin && hasAdmin && features.isCustomerMappingEnabled && (
            <Button onClick={() => gotoAdmin()} type="default" className="me-10px" icon={<SettingOutlined />} size="middle">
              Admin
            </Button>
          )}
          {isAdmin && (
            <Button onClick={() => gotoDashboard()} type="default" className="me-10px" icon={<TableOutlined />} size="middle">
              Dashboard
            </Button>
          )}
          <Button onClick={() => logout()} type="primary" icon={<LogoutOutlined />} size="middle">
            Logout
          </Button>
        </div>
      </StyledHeader>
    );
  }
}

export default Header;
