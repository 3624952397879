import { useEffect, useState } from "react";
import axios from "axios";
import { useAppDispatch } from "../store";
import { useMsal } from "@azure/msal-react";
import useAccessToken from "./useAccessToken";

function useInterceptors({ envConfig }) {
  const dispatch = useAppDispatch();
  const [ready, setReady] = useState(false);
  const { instance: msalInstance } = useMsal();
  const { accessToken, refreshAccessToken } = useAccessToken(envConfig);

  useEffect(() => {
    if (!envConfig || !accessToken) {
      return;
    }

    setReady(true);

    const requestInterceptor = axios.interceptors.request.use(
      function (config) {
        config.baseURL = envConfig.appConfig.baseApiUrl;
        config.headers.Authorization = `Bearer ${accessToken}`;

        const areHeadersEmpty = !config.headers["Content-Type"];
        if (areHeadersEmpty) {
          config.headers["Content-Type"] = "application/json";
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error) {
        if (!error?.response?.status) {
          return Promise.reject(error);
        }

        const originalRequest = { ...error.config };

        if (error.response.status === 401) {
          try {
            if (originalRequest._retry) {
              throw error;
            }

            originalRequest._retry = true;
            await refreshAccessToken(true);
            return axios(originalRequest);
          } catch (tokenError) {
            msalInstance.loginRedirect();
            return Promise.reject(tokenError);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch, envConfig, accessToken, refreshAccessToken, msalInstance]);

  return ready;
}

export default useInterceptors;
