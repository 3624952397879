import { useContext } from "react";
import { List, Typography } from "antd";

import { Attachment, PotentialDuplicate } from "../store/invoicesSlice";
import { FeatureFlags } from "../hooks/featureFlagsProvider";

import { explodeFileName } from "../utils";
import { useAppSelector } from "../store";

interface Props {
  attachment: Attachment;
  isAttachment: boolean;
}

function PotentialDuplicatesSection({ attachment, isAttachment }: Props) {
  const { features } = useContext(FeatureFlags);
  const potentialDuplicates: PotentialDuplicate[] = useAppSelector((state) => state.emails.potentialDuplicates);
  const currentInvoice = useAppSelector((state) => state.invoices.current);

  if (features?.showPotentialDuplicates) {
    let potentialDuplicateInvoiceIds: string[] = [];

    if (isAttachment) {
      potentialDuplicateInvoiceIds = currentInvoice.finalizedData?.potentialDuplicateInvoiceIds;
    } else {
      potentialDuplicateInvoiceIds = potentialDuplicates.find((potentialDuplicate) => potentialDuplicate.attachmentId === attachment.id)?.duplicates;
    }

    return (
      <List
        size="small"
        header={
          <Typography.Title style={{ fontSize: 14, marginTop: 5, marginBottom: 5 }} level={4}>
            Potential Duplicates
          </Typography.Title>
        }
        dataSource={potentialDuplicateInvoiceIds}
        renderItem={(item) => (
          <List.Item
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              window.open(
                `/invoice/${features.IsDetailsMultiFile ? item.split("[!]")[1] : item.split("[!]")[0]}?isInvoice=${!features.IsDetailsMultiFile}`,
                "_blank"
              );
            }}
          >
            {explodeFileName(item).filename}
          </List.Item>
        )}
      ></List>
    );
  }

  return <></>;
}

export default PotentialDuplicatesSection;
