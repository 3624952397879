import axios from "axios";

export const fetchFeatureFlags = async (): Promise<any> => {
  return axios.get(`system-settings`)
    .then((res: any) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return [];
      }
    })
    .catch((err: any) => {
      console.error(err);
      return [];
    });
};

