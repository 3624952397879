import React from "react";
import { Attachment, SalesForceField } from "../store/invoicesSlice";
import { formatAsUSD, getAttachmentInfo, getInvoiceTotalCalculated } from "../utils";
import { Col, Row, Table, Typography } from "antd";
import { useAppSelector } from "../store";
const { Text } = Typography;

interface Props {
  features: any;
  attachments: Attachment[];
}

const columns = [
  {
    title: "Serial Number",
    dataIndex: "serialNumber",
    key: "serialNumber",
  },
  {
    title: "Location",
    dataIndex: "assetLocation",
    key: "assetLocation",
  },
  {
    title: "Vendor",
    dataIndex: "vendorName",
    key: "vendorName",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: (text) => <span style={{ float: "right" }}>{formatAsUSD(text)}</span>,
  },
];

interface AttachmentInfo {
  key: number;
  serialNumber: string;
  assetLocation: string;
  vendorName: string;
  total: string;
}

function AttachmentInfoTable({ features, attachments }: Props) {
  const salesForceFields = useAppSelector((state) => state.invoices.salesForceFields);

  const attachmentInfo: AttachmentInfo[] = attachments.map((attachment, index) => {
    let totalCalculated = 0;
    if (attachment.finalizedData) {
      if (features.invoiceTotalValue) {
        const fields: SalesForceField[] = salesForceFields.map((field) => {
          const value = attachment.finalizedData[field.id];
          if (value) {
            return { ...field, value };
          }

          return field;
        });

        totalCalculated = getInvoiceTotalCalculated(fields, features);
      }
    }

    return getAttachmentInfo(attachment, totalCalculated, false, index);
  }) as unknown as AttachmentInfo[];

  const grandTotal: number = attachmentInfo.reduce((prev, attachmentInfo) => {
    const { total } = attachmentInfo;
    return total ? prev + parseFloat(total) : prev;
  }, 0);

  const renderFooter = () => {
    const numberOfAttachments = attachments.length;

    return (
      <Row>
        <Col span={12}>
          <Text style={{ fontSize: "12px" }} italic>
            Grand total ({numberOfAttachments} {numberOfAttachments === 1 ? "record" : "records"})
          </Text>
        </Col>
        <Col style={{ textAlign: "right" }} span={12}>
          <strong>{grandTotal ? formatAsUSD(grandTotal) : "-"}</strong>
        </Col>
      </Row>
    );
  };

  return <Table size="small" pagination={false} footer={renderFooter} columns={columns} dataSource={attachmentInfo} />;
}

export default AttachmentInfoTable;
