import { useState } from "react";
import styled from "styled-components";
import { delay } from "../utils";
import { Button, Typography, Spin } from "antd";

type Props = {
  src: string;
  envConfig: any;
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background: #303030;
`;

const Embed = styled.embed`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  objectfit: contain;
  position: absolute;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  opacity: 0.05;
  background: #000;
`;

const DownloadContainer = styled.div`
  width: 200px;
  text-align: center;
  align-self: center;
`;

const unsupportedExtensions = [".doc", ".docx", ".xls", ".xlsx", ".csv", ".mov", ".mp4"];

export default function DocumentRenderer({ src, envConfig }: Props) {
  const [overlay, setOverlay] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const encodeLink = (link: string) => {
    return `https://${envConfig.appConfig.storageAccountName}.blob.core.windows.net/dropzonehistory/` + encodeURI(link).replaceAll("#", "%23");
  };

  const hideOverlay = async () => {
    await delay(220);
    setOverlay(false);
  };

  const displayOverlay = () => {
    setOverlay(true);
  };

  if (!src) {
    return (
      <Container>
        <Spin size="large" className="white-spin" />
      </Container>
    );
  }

  const encodedSrc = encodeLink(src);

  if (unsupportedExtensions.some((extension) => encodedSrc.endsWith(extension))) {
    return (
      <Container>
        <DownloadContainer>
          <Typography.Text strong italic style={{ color: "white" }}>
            Unsupported document type.
          </Typography.Text>

          <Button
            style={{ width: "100%", marginTop: 10 }}
            onClick={() => {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              iframe.src = encodedSrc;
              document.body.appendChild(iframe);
              iframe.onload = () => {
                document.body.removeChild(iframe);
              };
            }}
          >
            Download document
          </Button>
        </DownloadContainer>
      </Container>
    );
  }

  return (
    <Container onMouseLeave={displayOverlay}>
      {isLoading ? <Spin size="large" className="white-spin" /> : <></>}
      <Embed onLoadedData={() => setIsLoading(false)} src={encodedSrc} />
      {overlay ? <Overlay onMouseEnter={hideOverlay} onClick={hideOverlay}></Overlay> : null}
    </Container>
  );
}
