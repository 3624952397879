import React, { useEffect, useState } from "react";
import { Button, Table, Modal } from "antd";

import { AuditLogRecord, fetchAuditLogByAttachmentId } from "../store/auditLogSlice";
import { useAppDispatch, useAppSelector } from "../store";
import { formatShortDate } from "../utils";

interface AuditLogProps {
  invoiceId: string;
}

const pageCount = 20;

function AuditLog({ invoiceId }: AuditLogProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [auditLogChanges, setAuditLogChanges] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const { data, loading, totalCount } = useAppSelector((state) => state.auditLog);
  const dispatch = useAppDispatch();

  const showModal = (entity: AuditLogRecord) => {
    var changes = entity.changes.map((item, index) => ({
      ...item,
      id: item.property+"-"+index,
    }));

    setAuditLogChanges(changes);
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setAuditLogChanges([]);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (invoiceId && invoiceId.length > 0) {
      setIsLoading(true);
      dispatch(fetchAuditLogByAttachmentId({ id: invoiceId }));
    }
  }, [invoiceId, dispatch]);

  useEffect(() => {
    setIsLoading(false);
  }, [loading]);

  const handleChanges = (entity: AuditLogRecord) => {
    return (
      <Button type="link" size="small" onClick={() => showModal(entity)}>
        View Changes
      </Button>
    );
  };

  const onPageChange = (newIndex: number) => {
    setPageIndex(newIndex);
    dispatch(fetchAuditLogByAttachmentId({ id: invoiceId, filter: { count: pageCount, page: newIndex } }));
  }

  const columns = [
    {
      title: "User",
      dataIndex: "user",
    },
    {
      title: "Event Date",
      dataIndex: "auditDate",
      render: (_, record) => {
        return <div>{formatShortDate(record.auditDate, true)}</div>
      },
    },
    {
      title: "Event Action",
      dataIndex: "auditAction",
    },
    {
      title: "Changes",
      dataIndex: "changes",
      render: (_, record) => handleChanges(record),
    },
  ];

  const changesColumns = [
    {
      title: "Field",
      dataIndex: "property",
    },
    {
      title: "Old Value",
      dataIndex: "oldValue",
    },
    {
      title: "New Value",
      dataIndex: "newValue",
    },
  ];

  return (
    <>
      <Table
        scroll={{ y: 300 }}
        className="custom-table"
        loading={isLoading}
        rowKey={(e) => e.id}
        columns={columns}
        dataSource={data}
        pagination={{
          onChange: (page) => {
            onPageChange(page);
          },
          total: totalCount,
          pageSize: pageCount,
          showSizeChanger: false,
          current: pageIndex,
        }}
      />
      <Modal title="Event changes" open={isModalOpen} onCancel={hideModal} cancelText="Close" okButtonProps={{ style: { display: "none" } }}>
        <Table className="custom-table" rowKey={(e) => e.id} pagination={false} columns={changesColumns} dataSource={auditLogChanges} />
      </Modal>
    </>
  );
}

export default AuditLog;
