import React from "react";
import { Attachment, SalesForceField } from "../store/invoicesSlice";
import { formatAsUSD, getAttachmentInfo, getInvoiceTotalCalculated } from "../utils";
import { List, Popover, Typography } from "antd";
import { useAppSelector } from "../store";
const { Text } = Typography;

interface Props {
  attachment: Attachment;
  features: any;
  children: any;
}

function AttachmentInfoPopover({ attachment, features, children }: Props) {
  const salesForceFields = useAppSelector((state) => state.invoices.salesForceFields);

  const renderInvoicePopoverContent = (attachment: Attachment) => {
    if (attachment.finalizedData) {
      let totalCalculated = 0;
      if (features.invoiceTotalValue) {
        const fields: SalesForceField[] = salesForceFields.map((field) => {
          const value = attachment.finalizedData[field.id];
          if (value) {
            return { ...field, value };
          }

          return field;
        });

        totalCalculated = getInvoiceTotalCalculated(fields, features);
      }

      const data = getAttachmentInfo(attachment, totalCalculated, true) as unknown as Array<{ label: string; text: number }>;

      const renderListItem = (item) => {
        const text = item.key === "total" ? formatAsUSD(item.text) : item.text;
        return (
          <List.Item style={{ padding: "2px 0", display: "flex", justifyContent: "space-between" }}>
            <Text style={{ fontSize: "12px", marginRight: "10px" }} italic>
              {item.label}
            </Text>{" "}
            <strong>{text}</strong>
          </List.Item>
        );
      };

      return <List dataSource={data} renderItem={renderListItem} />;
    }

    return "";
  };

  return (
    <Popover content={renderInvoicePopoverContent(attachment)} placement="bottomRight">
      {children}
    </Popover>
  );
}

export default AttachmentInfoPopover;
