import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Table, notification } from "antd";
import { useAppDispatch, useAppSelector } from "../store";
import { createExpenseVendors, fetchExpenseVendors } from "../store/expenseVendorsSlice";
import { debounce } from "lodash";

interface Props {
  envConfig: any;
}

const SearchStyle = {
  maxWidth: "20%",
  display: "flex",
  flex: 1,
};

const DEBOUNCE_TIME = 500;
const { Search } = Input;
const pageCount = 20;

function ExpenseSuppliers({ envConfig }: Props) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [pageIndex, setPageIndex] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data, loading, totalCount } = useAppSelector((state) => state.expenseVendors);

  useEffect(() => {
    dispatch(fetchExpenseVendors({}));
  }, [dispatch]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    form.resetFields();
    setIsModalOpen(false);
    setError(null);
  };

  const onPageChange = (newIndex: number) => {
    setPageIndex(newIndex);
    dispatch(fetchExpenseVendors({ filter: { count: pageCount, page: newIndex, search } }));
  }

  const onSearchChange = useCallback((value?: string) => {
    if (value !== "") {
      dispatch(fetchExpenseVendors({ filter: { count: pageCount, page: 1, search: value } }));
    } else {
      dispatch(fetchExpenseVendors({ filter: { count: pageCount, page: 1 } }));
    }
    setPageIndex(1);
  }, [dispatch]);

  const onSearchChangeDebounced = useMemo(() => debounce(onSearchChange, DEBOUNCE_TIME), [onSearchChange]);


  const onSubmit = (values) => {
    const {id, name} = values;

    setError(null);
    setIsLoading(true);
    createExpenseVendors(id ? id.toString() : "", name).then((res) => {
      if (res && res.success) {
        setPageIndex(1);
        dispatch(fetchExpenseVendors({}));
        hideModal();
        form.resetFields();
        setError(null);
        notification.open({
          message: "Expense Vendors",
          description: "Expense Vendor successfully added.",
        });
      }
    })
    .catch((err) => {
      setError(err.response.data);
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const columns = [
    {
      title: "SL Vendor ID",
      dataIndex: "sL_Vendor_ID__c",
    },
    {
      title: "Vendor Name",
      dataIndex: "name",
    }
  ];

  return (
    <>
      <Row>
        <Col span={1} />
        <Col span={22} className="container">
          <h2>Expense Suppliers</h2>
          <Row justify="space-between" style={{"marginTop": 20, "marginBottom": 20}}>
            <Search
              style={SearchStyle}
              onChange={({ target }) => {
                setSearch(target.value);
                onSearchChangeDebounced(target.value);
              }}
              allowClear
              placeholder="Search"
              size="middle"
              loading={loading}
              value={search}
            />
            <Button className="text-end" onClick={() => showModal()}>Add New</Button>
          </Row>
          <Table
            className="custom-table"
            style={{ width: "100%" }}
            loading={loading || isLoading}
            rowKey={(e) => e.sL_Vendor_ID__c + e.name}
            columns={columns}
            dataSource={data}
            pagination={{
              onChange: (page) => {
                onPageChange(page);
              },
              total: totalCount,
              pageSize: pageCount,
              showSizeChanger: false,
              current: pageIndex,
            }}
          />
        </Col>
        <Col span={1} />
      </Row>
      <Modal
        title="Add new expense supplier"
        open={isModalOpen}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onSubmit(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={hideModal}
        cancelText="Cancel"
        okText="Add"
        confirmLoading={isLoading}
      >
        <Form
          form={form}
          style={{ marginTop: `30px`, marginBottom: `35px` }}
        >
          <Form.Item
            label="SL Vendor ID"
            name="id"
            rules={[{ type: 'number', message: 'SL Vendor ID must be number' }]}
            colon
          >
            <InputNumber style={{ width: `100%` }} />
          </Form.Item>
          <Form.Item
            label="Vendor Name"
            name="name"
            rules={[{ required: true, message: 'Vendor Name required' }]}
            colon
          >
            <Input />
          </Form.Item>
        </Form>
        <span style={{color: "red"}}>{error ? error : ""}</span>
      </Modal>
    </>
  );
}

export default ExpenseSuppliers;
