import { useState, useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { setRoles, setUser } from "../store/userSlice";
import { useAppDispatch } from "../store";
import jwt_decode from "jwt-decode";
import { AccessTokenPayload } from "../types";

function useAccessToken(envConfig) {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const dispatch = useAppDispatch();

  const refreshAccessToken = useCallback(
    async (forceRefresh = false) => {
      if (accounts.length > 0) {
        const request = {
          scopes: [envConfig.auth.scope],
          account: accounts[0],
          forceRefresh,
        };

        dispatch(setUser(accounts[0].username));

        try {
          const response = await instance.acquireTokenSilent(request);
          setAccessToken(response.accessToken);
          const decoded: AccessTokenPayload = jwt_decode(response.accessToken);
          if (decoded.roles) {
            dispatch(setRoles(decoded.roles));
          }
          return response.accessToken;
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            try {
              await instance.acquireTokenRedirect(request);
              return null;
            } catch (redirectError) {
              return Promise.reject(redirectError);
            }
          }
          return Promise.reject(error);
        }
      }
      return null;
    },
    [envConfig, accounts, instance, dispatch]
  );

  useEffect(() => {
    if (!envConfig) {
      return;
    }
    refreshAccessToken();
  }, [envConfig, refreshAccessToken]);

  return { accessToken, refreshAccessToken };
}

export default useAccessToken;
