import React, { ChangeEvent, FC, useRef, useState } from "react";
import { Col, Input, InputRef, Row, Tag, Tooltip, message } from "antd";

type TagInputProps = {
  placeholder?: string;
  style?: React.CSSProperties;
  value: string[];
  onSearch: (value: string[]) => void;
};

const TagInput: FC<TagInputProps> = ({ value = [], onSearch, placeholder, style, ...props }) => {
  const [content, setContent] = useState<string>();
  const inputRef = useRef<InputRef>(null);

  const handleDelete = (tag: string) => {
    const newArr = value.filter((i) => i !== tag);
    onSearch(newArr);
    message.destroy();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
  };

  const handleBlur = () => {
    if (content) {
      if (value.includes(content)) {
        message.open({ type: "warning", content: `[tag: ${content}] already exists` });
        return;
      }
      onSearch([...value, content]);
      setContent("");
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && content) {
      if (value.includes(content)) {
        message.open({ type: "warning", content: `[tag: ${content}] already exists` });
        return;
      }
      onSearch([...value, content]);
      setContent("");
    }
  };

  return (
    <Row style={style}>
      <Col span={24} className="mb-5px">
        <Input
          allowClear
          ref={inputRef}
          placeholder={'Hit Enter for search'}
          value={content}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
          className="ant-input"
        />
      </Col>
      <Col span={24}>
        {value.map((item) =>
          item.length > 20 ? (
            <Tooltip title={item} key={item}>
              <Tag className="m-3px" closable onClose={() => handleDelete(item)}>{`${item.slice(0, 20)}...`}</Tag>
            </Tooltip>
          ) : (
            <Tag className="m-3px" closable key={item} onClose={() => handleDelete(item)}>
              {item}
            </Tag>
          )
        )}
      </Col>
    </Row>
  );
};

export default TagInput;
