import React, { useState } from "react";

import { Button, Col, Popover, Row, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { InfoCircleOutlined } from "@ant-design/icons";

import { CustomerRules as CustomerRulesType, fetchCustomerRules, removeCustomerRule } from "../store/customersSlice";
import CreateCustomerRuleModal from "./CreateCustomerRuleModal";
import { useDispatch } from "react-redux";

interface CustomerRulesProps {
  customerRules: CustomerRulesType[];
  loading: boolean;
  onAddRule: (userId: string, userName: string, rule: string) => void;
}

function CustomerRules({ onAddRule, loading, customerRules }: CustomerRulesProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleCreateRule = () => {
    setIsModalOpen(true);
  };

  const onSubmit = (userId: string, userName: string, rule: string) => {
    onAddRule(userId, userName, rule);
    setIsModalOpen(false);
  };

  const handleRemoveRule = async (userId, rule) => {
    await removeCustomerRule(userId, rule);
    dispatch(fetchCustomerRules());
  };

  const columns: ColumnsType<CustomerRulesType> = [
    {
      title: "DES",
      dataIndex: "userName",
      width: 250,
    },
    {
      title: "Rules",
      dataIndex: "rules",
      render: (rules, { userId }) =>
        rules.map((rule) => (
          <Tag className="m-3px" closable key={rule} onClose={() => handleRemoveRule(userId, rule)}>
            {rule}
          </Tag>
        )),
    },
  ];

  const renderPopoverContent = () => {
    return "Creating a rule will allow you to specify a DES to which you will assign a customer’s name. Any time an invoice comes in that contains that customer’s name, it will automatically be assigned to the specified DES that you select in the rule.";
  };

  return (
    <>
      <Row className="mb-20px">
        <Col span={24}>
          <Button type="default" onClick={handleCreateRule}>
            Create Rule
          </Button>
          <Popover placement="right" overlayStyle={{ width: 600 }} content={renderPopoverContent()}>
            <InfoCircleOutlined style={{ margin: 5, marginLeft: 10, cursor: "pointer" }} />
          </Popover>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table loading={loading} rowKey={(e) => e.userId} columns={columns} dataSource={customerRules} pagination={false} />
        </Col>
      </Row>

      <CreateCustomerRuleModal isModalOpen={isModalOpen} onSubmit={onSubmit} onCancel={() => setIsModalOpen(false)}></CreateCustomerRuleModal>
    </>
  );
}

export default CustomerRules;
