import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPresenceUsersPayload } from "../types";

const signalRSlice = createSlice({
  name: "signalRHub",
  initialState: {
    presenceUsers: [],
  },
  reducers: {
    updatePresenceUsers: (state, action: PayloadAction<IPresenceUsersPayload>) => {
      state.presenceUsers = action.payload.items;
    }
  },
  extraReducers: {
  }
});

export const { updatePresenceUsers } = signalRSlice.actions;
export default signalRSlice.reducer;
