import { useContext, useEffect } from "react";
import { List, Spin, Typography } from "antd";

import { Attachment, fetchRelatedAttachments, resetRelatedAttachments } from "../store/invoicesSlice";
import { FeatureFlags } from "../hooks/featureFlagsProvider";

import { useAppDispatch, useAppSelector } from "../store";

interface Props {
  attachment: Attachment;
}

function RelatedDocumentsSecion({ attachment }: Props) {
  const { features } = useContext(FeatureFlags);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.invoices.loadingRelatedAttachments);
  const relatedAttachments = useAppSelector((state) => state.invoices.current.finalizedData.relatedAttachments || []);

  useEffect(() => {
    if (!attachment?.finalizedData?.relatedAttachmentsIds?.length) {
      dispatch(resetRelatedAttachments());
      return;
    }

    dispatch(fetchRelatedAttachments(attachment?.finalizedData?.relatedAttachmentsIds));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment?.id]);

  if (!attachment?.finalizedData?.relatedAttachmentsIds?.length) {
    return <></>;
  }

  return (
    <Spin spinning={isLoading}>
      <List
        size="small"
        header={
          <Typography.Title style={{ fontSize: 14, marginTop: 5, marginBottom: 5 }} level={4}>
            Related Documents
          </Typography.Title>
        }
        dataSource={relatedAttachments}
        renderItem={(item) => (
          <List.Item
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              window.open(`/invoice/${item.emailId}?isInvoice=${!features.IsDetailsMultiFile}`, "_blank");
            }}
          >
            {`${item?.finalizedData?.customerName} - ${item?.finalizedData?.plainFilename}`}
          </List.Item>
        )}
      ></List>
    </Spin>
  );
}

export default RelatedDocumentsSecion;
