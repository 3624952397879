import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { Email } from "../types";
import { Attachment, FinalizedData, PotentialDuplicate } from "./invoicesSlice";

import { ObjectToQueryParam, StatusCounts, mapStatusCounts } from "../utils/helpers";
import { notification } from "antd";

export const fetchEmails = createAsyncThunk(
  "emails/fetch",
  async ({ filter, controller }: { filter?: { statusId?: number; sortColumn?: string; sortDirection?: string }; controller }, { getState }) => {
    const queryParams = ObjectToQueryParam(filter);

    //@ts-ignore
    const prevQueryParams = getState().emails.queryParams;

    if (prevQueryParams === queryParams) {
      return Promise.reject();
    }

    if (window.history.pushState) {
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      if (queryParams) {
        newurl += `?${queryParams}`;
        window.history.pushState({ path: newurl }, "", newurl);
      }
    }

    try {
      const response = await axios.get(`/emails?${queryParams}`, { signal: controller.signal });
      if (response.status === 200) {
        return { response: response.data, queryParams };
      } else {
        console.error(`Http Error! Status: ${response.status}`);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        return Promise.reject();
      }
    }
  }
);

export const fetchEmail = createAsyncThunk("email/fetch", async (id: string) => {
  try {
    const response = await axios.get(`/emails/${id}`);
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      notification.open({
        message: "Email Not Found",
        description: `The email with ID ${id} was not found in the database.`,
      });
    }

    return Promise.reject();
  }
});

export const fetchInvoices = createAsyncThunk("invoices/fetch", async (id: string) => {
  return axios.get(`/invoices?emailId=${id}`).then((res) => res.data);
});

export const fetchEmailCounts = createAsyncThunk("emails/counts", async ({ controller }: { controller }, { getState }) => {
  try {
    const response = await axios.get(`/emails-counts`, { signal: controller.signal });
    if (response.status === 200) {
      return response.data;
    } else {
      console.error(`Http Error! Status: ${response.status}`);
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      console.error(error);
    }
  }
});

// Then, handle actions in your reducers:
const emailsSlice = createSlice({
  name: "emails",
  initialState: {
    loading: false,
    loadingInvoices: false,
    data: [] as Email[],
    invoices: [] as Attachment[],
    potentialDuplicates: [] as PotentialDuplicate[],
    queryParams: "",
    current: {} as Email,
    statusCounts: [] as StatusCounts[],
    totalCount: 0,
    page: 1,
    historical: false,
    originalStatus: "",
    connection: null,
  },
  reducers: {
    setEmailInvoiceData(state, action: PayloadAction<{ attachmentId: string; finalizedData: FinalizedData }>) {
      const { attachmentId, finalizedData } = action.payload;

      return {
        ...state,
        invoices: state.invoices.map((invoice) =>
          invoice.id === attachmentId
            ? {
                ...invoice,
                finalizedData,
              }
            : invoice
        ),
      };
    },
    setPotentialDuplicates(state, action: PayloadAction<{ attachmentId: string; duplicates: string[] }>) {
      const { attachmentId, duplicates } = action.payload;

      return {
        ...state,
        potentialDuplicates: state.potentialDuplicates.map((potentialDuplicate) =>
          potentialDuplicate.attachmentId === attachmentId
            ? {
                ...potentialDuplicate,
                duplicates,
              }
            : potentialDuplicate
        ),
      };
    },
    resetCurrentEmail(state) {
      return { ...state, invoices: [], current: {} as Email };
    },
  },
  extraReducers: {
    [fetchEmails.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.data = action.payload.response.data;
        state.page = action.payload.response.page;
        state.totalCount = action.payload.response.totalCount;
        state.queryParams = action.payload.queryParams;
        state.historical = action.payload.historical;
        state.loading = false;
      }
    },
    [fetchEmails.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchEmails.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [fetchEmail.fulfilled.type]: (state, action) => {
      state.current = action.payload;
      //state.originalStatus = state.current.finalizedData.status;
      state.loading = false;
    },
    [fetchEmail.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchEmail.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [fetchInvoices.fulfilled.type]: (state, action) => {
      const invoices: Attachment[] = action.payload.data;
      state.invoices = invoices;

      const potentialDuplicates: PotentialDuplicate[] = invoices.map((invoice) => ({
        attachmentId: invoice.id,
        duplicates: invoice.finalizedData.potentialDuplicateInvoiceIds,
      }));

      state.potentialDuplicates = potentialDuplicates;
      state.loadingInvoices = false;
    },
    [fetchInvoices.pending.type]: (state, action) => {
      state.loadingInvoices = true;
    },
    [fetchInvoices.rejected.type]: (state, action) => {
      state.loadingInvoices = false;
    },

    [fetchEmailCounts.fulfilled.type]: (state, action) => {
      state.statusCounts = mapStatusCounts(action.payload);
    },
    [fetchEmailCounts.pending.type]: (state, action) => {},
    [fetchEmailCounts.rejected.type]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setEmailInvoiceData, setPotentialDuplicates, resetCurrentEmail } = emailsSlice.actions;
export default emailsSlice.reducer;

export type Status = "New" | "Needs Review" | "Corrections Needed" | "Approved" | "Rejected";

export interface StatusObj {
  id: number;
  label: string;
  showInFilter: boolean;
  key: string;
  color?: string;
}

export enum StatusEnum {
  New = 1,
  NeedsReview,
  CorrectionsNeeded,
  Approved,
  Rejected,
  Submitted,
  OcrFailure,
  SenttoSalesforce,
  PermenantRejection,
}

export const StatusList: StatusObj[] = [
  {
    label: "New",
    id: 1,
    showInFilter: false,
    key: "new",
  },
  {
    label: "Needs Review",
    id: 2,
    showInFilter: true,
    key: "needsReview",
    color: "#0984e3",
  },
  {
    label: "Approved",
    id: 4,
    showInFilter: true,
    key: "approved",
    color: "#00b894",
  },
  {
    label: "Rejected",
    id: 5,
    showInFilter: true,
    key: "rejected",
    color: "#d63031",
  },
  {
    label: "Sent to Salesforce",
    id: 8,
    showInFilter: true,
    key: "sentToSalesforce",
    color: "#663399",
  },
];
