import React, { useState } from "react";
import { ColumnsType } from "antd/lib/table";

import { AssignedCustomer, Customer } from "../store/customersSlice";
import { Button, Col, Row, Table } from "antd";
import AssignToModal from "./AssignToModal";

interface AssignedCustomersProps {
  customers: AssignedCustomer[];
  loading: boolean;
  onAssignCustomers: (userId: string, customerId: React.Key[]) => void;
}

function AssignedCustomers(props: AssignedCustomersProps) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const expandedColumns: ColumnsType<Customer> = [
    {
      title: "Customers",
      dataIndex: "name",
    },
  ];

  const expandedRowRender = (record: AssignedCustomer) => {
    return (
      <Table
        loading={props.loading}
        rowKey={(e) => e.id}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys,
          ...rowSelection,
        }}
        columns={expandedColumns}
        dataSource={record.customers}
        pagination={false}
      ></Table>
    );
  };

  const columns: ColumnsType<AssignedCustomer> = [
    {
      title: "Users",
      dataIndex: "username",
    },
  ];

  const handleAssignTo = () => {
    setIsModalOpen(true);
  };

  const onClosePopup = (refresh: boolean, userId: string = "") => {
    if (userId.length > 0) {
      props.onAssignCustomers(userId, selectedRowKeys);
    }
    setIsModalOpen(false);
    setSelectedRowKeys([]);
  };

  return (
    <>
      <Row className="mb-20px">
        <Col span={24}>
          <Button type="default" disabled={selectedRowKeys.length === 0} onClick={handleAssignTo}>
            Assign To
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={props.loading}
            rowKey={(e) => e.assignedTo}
            columns={columns}
            dataSource={props.customers}
            expandable={{
              expandedRowRender: (record) => expandedRowRender(record),
            }}
            pagination={false}
          />
        </Col>
      </Row>

      <AssignToModal isModalOpen={isModalOpen} emails={[]} invoices={[]} isCustomer={true} closePopup={onClosePopup}></AssignToModal>
    </>
  );
}

export default AssignedCustomers;
