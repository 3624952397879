import React, { useContext } from "react";

import { UserOutlined, LockFilled, ReadFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

import { FeatureFlags } from "../hooks/featureFlagsProvider";
import { IPresenceUser } from "../types";

interface Props {
  presenceUsers: IPresenceUser[];
}

function UserPresenceIcon({ presenceUsers }: Props) {
  const { features } = useContext(FeatureFlags);

  const renderUser = (user, index) => {
    let icon = null;

    const firstPresenceUserNotReadOnlyIndex = presenceUsers.findIndex((user) => !user.readOnly);
    if (index === firstPresenceUserNotReadOnlyIndex && features.presenceLock && !user.readOnly) {
      icon = <LockFilled />;
    }

    if (user.readOnly) {
      icon = <ReadFilled />;
    }

    return <div key={user.id}>{user.userName} {icon}</div>;
  };

  return presenceUsers.length ? (
    <Tooltip overlayStyle={{ maxWidth: '600px' }} title={presenceUsers.map(renderUser)}>
      <UserOutlined />
    </Tooltip>
  ) : null;
}

export default UserPresenceIcon;
