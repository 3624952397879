const size = {
  xs: "320px",
  sm: "768px",
  lg: "1200px",
};
const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  lg: `(min-width: ${size.lg})`,
};

export const breakpoint = {
  size,
  device,
};

export const colors = {
  primary: "#393B40",
  secondary: "#c2c7cf",
  lightGray: "#ecf0f1",

  approvedStatusColor: "#00b894",
  needsReviewStatusColor: "#0984e3",
  rejectedStatusColor: "#d63031",
  sentToSalesforceStatusColor: "#663399",
  correctionsNeededColor: "#e67e22",
};
