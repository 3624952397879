import axios from "axios";

export const searchServiceTeams = async (search: string): Promise<any[]> => {
  if (search.length >= 3) {
    return axios.get(`service-teams/search?search=${search}`).then((res: any) => {
      if (res.status === 200 && res.data) {
        const list = res.data.map((d: any) => {
          return {
            key: d.serviceTeam,
            label: d.serviceTeam,
            value: d.serviceTeam
          }
        });
        return list;
      } else {
        return [];
      }
    });
  } else {
    return [];
  }
};
