import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Col, Row, Tabs, TabsProps } from "antd";

import { assignCustomersToUser, fetchAssignedCustomers, fetchCustomers, fetchCustomerRules, addCustomerRule } from "../store/customersSlice";
import AssignedCustomers from "./AssignedCustomers";
import UnassignedCustomers from "./UnassignedCustomers";
import CustomerRules from "./CustomerRules";
import { useAppSelector } from "../store";

interface Props {
  envConfig: any;
}

function CustomerMapping({ envConfig }: Props) {
  const { assignedCustomers, unassignedCustomers, customerRules, loading } = useAppSelector((state) => state.customers);
  const [isLoading, setIsLoading] = useState(false);
  const roles = useAppSelector((state) => state.user?.roles);
  const hasAdmin = roles.includes("Admin");
  const dispatch = useDispatch();
  const history = useHistory();

  const loadData = useCallback(() => {
    dispatch(fetchCustomers());
    dispatch(fetchAssignedCustomers());
    dispatch(fetchCustomerRules());
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!hasAdmin) {
      const url = `/`;
      history.push(url);
    }
  }, [hasAdmin, history]);

  const handleAddCustomerRule = async (userId: string, userName: string, rule: string) => {
    await addCustomerRule(userId, userName, rule);
    dispatch(fetchCustomerRules());
  };

  const assignCustomers = (userId: string, customerId: React.Key[]) => {
    setIsLoading(true);
    assignCustomersToUser(userId, customerId)
      .then((res) => {
        if (res && res.success) {
          loadData();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Assigned Customers",
      children: <AssignedCustomers customers={assignedCustomers} loading={isLoading} onAssignCustomers={assignCustomers} />,
    },
    {
      key: "2",
      label: "Unassigned Customers",
      children: <UnassignedCustomers customers={unassignedCustomers} loading={isLoading} onAssignCustomers={assignCustomers} />,
    },
    {
      key: "3",
      label: "Customer Rules",
      children: <CustomerRules customerRules={customerRules} loading={isLoading} onAddRule={handleAddCustomerRule} />,
    },
  ];

  return (
    <Row>
      <Col span={1} />
      <Col span={22} className="container">
        <Tabs defaultActiveKey="1" items={items} />
      </Col>
      <Col span={1} />
    </Row>
  );
}

export default CustomerMapping;
