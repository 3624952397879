import React from "react";
import { useState } from "react";

import { Input, Modal, Row } from "antd";

import { searchUsers } from "../store/userSlice";
import DebounceSelect from "./DebounceSelect";

interface CreateCustomerRuleModalProps {
  isModalOpen: boolean;
  onSubmit: (userId: string, userName: string, rule: string) => void;
  onCancel: () => void;
}

function CreateCustomerRuleModal({ onSubmit, onCancel, isModalOpen }: CreateCustomerRuleModalProps) {
  const [user, setUser] = useState<any>();
  const [ruleValue, setRuleValue] = useState<string>("");

  const fetchUserList = async (criteria: string): Promise<any[]> => {
    return searchUsers(criteria);
  };

  const handleCancel = () => {
    setUser(null);
    setRuleValue("");
    onCancel();
  };

  const handleCreateCustomerRule = () => {
    const { value: userId, label: username } = user;
    onSubmit(userId, username, ruleValue);
    setRuleValue("");
  };

  return (
    <Modal title="Create Customer Rule" open={isModalOpen} width={"600px"} onCancel={handleCancel} onOk={handleCreateCustomerRule}>
      <Row>
        <DebounceSelect
          value={user}
          placeholder="Select DES"
          fetchOptions={fetchUserList}
          onChange={(newValue) => {
            setUser(newValue as any);
          }}
          style={{ width: "100%" }}
        />
      </Row>
      <Row justify="start" align="middle" style={{ marginTop: "10px" }}>
        <Input size="large" placeholder="Enter rule" value={ruleValue} onChange={(e) => setRuleValue(e.target.value)} />
      </Row>
    </Modal>
  );
}

export default CreateCustomerRuleModal;
