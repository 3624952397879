import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "antd";

import { FeatureFlags } from "../hooks/featureFlagsProvider";
import CircleProgress from "./CircleProgress";
import { Filter } from "../pages/Dashboard";
import { StatusCounts, StatusObj } from "../utils";
import { useAppSelector } from "../store";

interface Props {
  statusCounts: StatusCounts[];
  filter: Filter;
  onFilterChange: (key: keyof Filter, value?: number | string) => void;
}

function StatusFilters({ statusCounts, filter, onFilterChange }: Props) {
  const loadingInvoices = useAppSelector((state) => state.invoices.loading);
  const { features } = useContext(FeatureFlags);
  const [statusList, setStatusList] = useState<StatusObj[]>([]);

  useEffect(() => {
    const statusArray = features?.filterStatusOptions;
    if (statusArray && statusArray.length > 0) {
      statusArray.sort((a, b) => a.displayOrder - b.displayOrder);

      setStatusList(statusArray);
    }
  }, [features]);

  const isStatusSelected = (status: string) => {
    return filter.status === status;
  };

  const handleStatusFilter = useCallback(
    (status: string) => {
      onFilterChange("status", status);
    },
    [onFilterChange]
  );

  const getCount = (id: number): number => {
    if (Array.isArray(statusCounts)) {
      const statusCountIdx = statusCounts.findIndex((sc) => sc.key === `${id}`);
      if (statusCountIdx > -1) {
        return statusCounts[statusCountIdx].count ?? 0;
      }
    }
    return 0;
  };

  const getPercentage = (id: number): number => {
    if (Array.isArray(statusCounts)) {
      const statusCountIdx = statusCounts.findIndex((sc) => sc.key === `${id}`);
      if (statusCountIdx > -1) {
        return statusCounts[statusCountIdx].percentage ?? 0;
      }
    }
    return 0;
  };

  if (features.pageLayout.useDefaultLayout) {
    return (
      <>
        {statusList
          .filter((s) => s.showInFilter)
          .map((status, index) => (
            <CircleProgress
              key={index}
              onClick={() => handleStatusFilter(status.key)}
              percent={features?.hideStatusPercentageInBuckets ? 0 : getPercentage(status.id) * 100}
              size={170}
              title={status.hideCounter ? null : getCount(status.id)}
              subtitle={status.label}
              color={isStatusSelected(status.key) ? '#000' : status.color}
            />
          ))}
      </>
    );
  } else {
    return (
      <>
        {statusList
          .filter((s) => s.showInFilter)
          .map((status, index) => (
            <Button
              key={status.id}
              size="small"
              className={`me-5px fs-12px mb-4px bg-gray ${isStatusSelected(status.key) ? "selected" : ""}`}
              onClick={() => handleStatusFilter(status.key)}
              disabled={loadingInvoices}
            >
              {status.label} {getCount(status.id)}
            </Button>
          ))}
      </>
    );
  }
}

export default StatusFilters;
