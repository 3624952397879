import { useContext } from "react";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  ExclamationCircleFilled,
  HourglassOutlined,
  SearchOutlined,
  SplitCellsOutlined,
} from "@ant-design/icons";

import { FeatureFlags } from "../hooks/featureFlagsProvider";

interface Props {
  status: string;
}

function AttachmentIcon({ status }: Props) {
  const { features } = useContext(FeatureFlags);

  if (features.useStatusIcons) {
    switch (status) {
      case "New":
      case "Approved":
      case "Processing":
      case "OCR Completed":
        return (
          <HourglassOutlined
            style={{
              color: "#00FF00",
              fontSize: "16px",
              margin: "0 .5em",
            }}
          />
        );
      case "Needs Review":
        return (
          <SearchOutlined
            style={{
              color: "#ECAA0D",
              fontSize: "16px",
              margin: "0 .5em",
            }}
          />
        );
      case "Issues":
        return (
          <ExclamationCircleFilled
            style={{
              color: "#ECAA0D",
              fontSize: "16px",
              margin: "0 .5em",
            }}
          />
        );
      case "Rejected":
        return (
          <CloseCircleOutlined
            style={{
              color: "#FF0000",
              fontSize: "16px",
              margin: "0 .5em",
            }}
          />
        );
      case "Salesforce Upload Complete":
        return (
          <CheckCircleFilled
            style={{
              color: "#00aa00",
              fontSize: "16px",
              margin: "0 .5em",
            }}
          />
        );
      case "Salesforce Upload Failure":
        return (
          <ExclamationCircleFilled
            style={{
              color: "#AA0000",
              fontSize: "16px",
              margin: "0 .5em",
            }}
          />
        );
      case "Split Processed":
        return (
          <SplitCellsOutlined
            style={{
              color: "#00FF00",
              fontSize: "16px",
              margin: "0 .5em",
            }}
          />
        );
    }
  }

  if (features?.showCheckedStatus.includes(status)) {
    return (
      <CheckCircleFilled
        style={{
          color: "#00AA00",
          fontSize: "16px",
          margin: "0 .5em",
        }}
      />
    );
  } else if (features?.showFailedStatus.includes(status)) {
    return (
      <ExclamationCircleFilled
        style={{
          color: "#FF0000",
          fontSize: "16px",
          margin: "0 .5em",
        }}
      />
    );
  }

  return <></>;
}

export default AttachmentIcon;
