import { Spin } from "antd";
import styled from "styled-components";

const CenteredDiv = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const Loading = () => (
  <CenteredDiv>
    <Spin size="large" />
  </CenteredDiv>
);

export default Loading;
