import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { guid } from "../utils";

export type MessageType = "success" | "info" | "warning" | "error";
interface MessageState {
  message: string;
  type: MessageType;
  timeout: number; //in seconds
  id: string;
  showIcon: boolean;
  description: string;
}
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export const pushMessage = createAsyncThunk(
  "messages/push",
  async (messageState: Optional<MessageState, "id" | "timeout" | "showIcon">, { dispatch }) => {
    const id = guid();
    const timeout = messageState.timeout || 5;
    const showIcon = messageState.showIcon || true;
    dispatch(addMessage({ ...messageState, timeout, id, showIcon }));

    return new Promise((_, reject) => setTimeout(() => dispatch(removeMessage(id)), timeout * 1500));
  }
);
const messagesSlice = createSlice({
  name: "messages",
  initialState: [] as MessageState[],
  reducers: {
    addMessage: (state, action: PayloadAction<MessageState>) => {
      const message = {
        id: action.payload.id,
        message: action.payload.message,
        type: action.payload.type,
        timeout: action.payload.timeout,
        showIcon: action.payload.showIcon,
        description: action.payload.description,
      };
      state.push(message);
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      const index = state.findIndex((el) => el.id === action.payload);
      state.splice(index, 1);
    },
  },
});

export const { addMessage, removeMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
