import { useEffect } from "react";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

import { updatePresenceUsers } from "../store/signalRSlice";
import { useAppDispatch, useAppSelector } from "../store";
import { IPresenceUsersPayload } from "../types";

interface Props {
  envConfig: any;
  id: string;
  readOnly: boolean;
}

let connection: HubConnection = null;

function useSignalR({ envConfig, id, readOnly }: Props) {
  const dispatch = useAppDispatch();
  const userName = useAppSelector((state) => state.user?.userName);
  const url = envConfig.appConfig.signalUrl;

  useEffect(() => {
    connection = new HubConnectionBuilder().withUrl(url).configureLogging(LogLevel.Error).withAutomaticReconnect().build();

    connection
      .start()
      .then(() => {
        if (connection.state === "Connected") {
          if (!id) {
            GetPresenceUsers(connection);
          }

          if (id && userName) {
            AddUserToPresence(connection, userName, id, readOnly);
          }
        }

        connection.on("newMessage", (res) => {
          if (res && res !== "null") {
            const payload: IPresenceUsersPayload = JSON.parse(res);
            dispatch(updatePresenceUsers(payload));
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      if (connection.state === "Connected") {
        RemoveUserFromPresence(connection, userName, id);
      }
    };
    // eslint-disable-next-line
  }, []);

  const GetPresenceUsers = (connection) => {
    connection
      .invoke("GetPresenceUsers")
      .then()
      .catch((error) => {
        console.error(error);
      });
  };

  const AddUserToPresence = (connection, userName: string, id: string, readOnly: boolean) => {
    connection
      .invoke("AddUserToPresence", userName, id, readOnly)
      .then()
      .catch((error) => {
        console.error(error);
      });
  };

  const RemoveUserFromPresence = (connection, userName: string, id: string) => {
    connection
      .invoke("RemoveUserFromPresence", userName, id)
      .then()
      .catch((error) => {
        console.error(error);
      });
  };
}

export default useSignalR;
